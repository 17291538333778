import React from "react";
import Layout from "../components/layout-default";
import Hero from "../components/hero";
import Breadcrumbs from "../components/breadcrumbs";
import LosMuetros from "../images/Los_Muertos.png";
import LosMuetros2 from "../images/Muertos.png";
import RF_Halloween_Social from "../images/RF_Halloween_Social.jpg";
import RfBowling from "../images/bowlingNight.jpg";
import nextIcon from "../images/icons8-next-50.png";

import { Link } from "gatsby";

const crumbs = [
  {
    label: "Home",
    link: "",
  },
  {
    label: "News",
    link: "news",
  },
];

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: LosMuetros2,
    };
  }

  handleImageSwitch = () => {
    this.setState((prevState) => ({
      currentImage:
        prevState.currentImage === LosMuetros2 ? LosMuetros : LosMuetros2,
    }));
  };

  render() {
    return (
      <Layout
        title="News | RF Dance"
        pathname="news/"
        noHero="no-hero"
        className="news_page"
      >
        <Hero titleRed={`RF`} titleWhite={`News`} />

        <div className="content-block">
          <div className="container">
            <Breadcrumbs crumbs={crumbs} />
            <div className="news_area">
              {/* English */}
              <h3>English:</h3>
              <div className="english-event">
                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=57&sVT=83&sView=day&sLoc=0&sTrn=100000131&date=10/12/24">
                      <img src={RfBowling} alt="RF Bowling Night Out" />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">RF Bowling Night Out</h3>
                    <div className="news_content_area">
                      <p>
                        Join us for an exciting RF Bowling Night Out on
                        Saturday, October 12th, from 7:00 pm to 9:00 pm at
                        Fountain Bowl, 17110 Brookhurst St, Fountain Valley, CA.
                        Get ready for a fun-filled evening with games and music.
                        Bring your friends and family to enjoy a night of
                        bowling and socializing. For just $25 per person, it
                        will include a lane and a pair of shoes; you'll have a
                        great time while making memories with your fellow
                        dancers and friends. Take advantage of this exciting
                        event!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=57&sVT=83&sView=day&sLoc=0&sTrn=100000131&date=10/12/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <img
                      src={this.state.currentImage}
                      alt="Danza de Los Muertos"
                      className="event-image"
                    />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={nextIcon}
                        alt="Next"
                        style={{
                          height: "30px",
                          width: "30px",
                          rotate: "180deg",
                          padding: "2px",
                        }}
                        onClick={this.handleImageSwitch}
                      />
                      <img
                        src={nextIcon}
                        alt="Next"
                        style={{
                          height: "30px",
                          width: "30px",
                          padding: "2px",
                        }}
                        onClick={this.handleImageSwitch}
                      />
                    </div>
                  </div>

                  <div className="news_content">
                    <h3 className="news_heading">Danza de Los Muertos </h3>
                    <div className="news_content_area">
                      <p>
                        Get ready for an unforgettable night at Danza De Los
                        Muertos! Join us on Friday, October 25th, for an
                        exciting evening of dance, music, and culture at RF
                        Dance Center, located at 1517 N. Main St, Santa Ana, CA.
                        The event kicks off at 7:00 pm and runs until 10:00 pm,
                        featuring an incredible lineup of dance workshops led by
                        Jorge and Friné, coming all the way from Mexico, and
                        Zeke, who is traveling from Chicago!
                      </p>
                      <h4>Agenda:</h4>
                      <ul>
                        <li>
                          <p>
                            <b>7:00 pm:</b> Quebradita with Jorge & Friné
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>7:00 pm:</b> Cumbia hines with Zeke
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>8:00 pm:</b> Cumbia Turn Patterns with Zeke
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>8:00 pm:</b> Norteña with Jorge & Friné
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>9:00 pm:</b> Cumbia Guaracha with Zeke
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>9:00 pm:</b> Huapango with Jorge & Friné
                          </p>
                        </li>
                      </ul>
                      <p>
                        Whether you're perfecting your Quebradita or learning
                        new Cumbia moves, there’s something for everyone! This
                        is the ultimate way to celebrate the season with your
                        fellow dancers.
                      </p>
                      <h4>Cover Prices:</h4>
                      <ul>
                        <li>
                          <p>
                            <b>$49</b> – RF Members (non-refundable)
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>$59</b> – Pre-sale (non-refundable)
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>$79</b> – Door Price
                          </p>
                        </li>
                      </ul>
                      <p>Don’t miss out on this incredible night of dancing!</p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=46&sVT=68&sLevel=4&sView=day&sLoc=1&sTrn=100000158&date=10/25/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=48&sVT=65&sView=day&sLoc=1&sTrn=100000131&date=10/26/24">
                      <img
                        src={RF_Halloween_Social}
                        alt="  RF Halloween Social "
                      />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">RF Halloween Social</h3>
                    <div className="news_content_area">
                      <p>
                        Get ready for a spooktacular night at our RF Halloween
                        Social! Join us at RF Dance Center on Saturday, October
                        26th, from 9:00 pm to 2:00 am. The evening kicks off
                        with a special Banda class by Jorge & Friné, coming all
                        the way from Mexico, at 9:00 pm at the same time Zeke,
                        from Chicago will teach a Cumbia Guaracha class! Then,
                        dance the night away with DJ Real Deal and DJ Noe until
                        2:00 am. Don't forget to dress in your best Halloween
                        costume for a chance to win $100 in our costume
                        competition! Enjoy the spooky decorations as we
                        transform the venue into a haunted wonderland. Tickets
                        are $20 for RF Members (non-refundable), $30 for
                        pre-sale tickets (non-members, non-refundable), and $50
                        at the door. Secure your spot now and don’t miss the
                        Halloween dance event of the year!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=48&sVT=65&sView=day&sLoc=1&sTrn=100000131&date=10/26/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* Spanish */}
              <h3>Spanish:</h3>
              <div className="spanish-event">
                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=57&sVT=83&sView=day&sLoc=0&sTrn=100000131&date=10/12/24">
                      <img src={RfBowling} alt="RF Bowling Night Out" />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">RF Bowling Night Out</h3>
                    <div className="news_content_area">
                      <p>
                        Únete a nosotros para una emocionante noche de boliche
                        con RF el Sabado 12 de octubre, de 7:00 pm a 9:00 pm en
                        Fountain Bowl, 17110 Brookhurst St, Fountain Valley, CA!
                        Prepárate para una noche llena de diversión con juegos y
                        música. Trae a tus amigos y familiares para disfrutar de
                        una noche de boliche y socialización. Por solo $25 por
                        persona, incluye una pista y un par de zapatos. ¡Te
                        divertirás mucho creando recuerdos con tus compañeros
                        bailarines y amigos! ¡Aprovecha este emocionante evento!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=57&sVT=83&sView=day&sLoc=0&sTrn=100000131&date=10/12/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <img
                      src={this.state.currentImage}
                      alt="Danza de Los Muertos"
                    />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={nextIcon}
                        alt="Next"
                        style={{
                          height: "30px",
                          width: "30px",
                          rotate: "180deg",
                          padding: "2px",
                        }}
                        onClick={this.handleImageSwitch}
                      />
                      <img
                        src={nextIcon}
                        alt="Next"
                        style={{
                          height: "30px",
                          width: "30px",
                          padding: "2px",
                        }}
                        onClick={this.handleImageSwitch}
                      />
                    </div>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Danza de Los Muertos </h3>
                    <div className="news_content_area">
                      <p>
                        Prepárate para una noche inolvidable en Danza De Los
                        Muertos! Únete a nosotros el viernes 25 de octubre para
                        una emocionante noche de baile, música y cultura en RF
                        Dance Center, ubicado en 1517 N. Main St, Santa Ana, CA.
                        El evento comenzará a las 7:00 pm y se extenderá hasta
                        las 10:00 pm, ¡con una increíble serie de talleres de
                        baile a cargo de Jorge y Friné, que vienen desde México,
                        y de Zeke, que viene de Chicago!
                      </p>
                      <h4>Agenda:</h4>
                      <ul>
                        <li>
                          <p>
                            <b>7:00 pm:</b> Quebradita con Jorge y Friné
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>7:00 pm:</b> Cumbia Shines con Zeke
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>8:00 pm:</b> Cumbia Turn Patterns con Zeke
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>8:00 pm:</b> Norteña con Jorge y Friné
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>9:00 pm:</b> Cumbia Guaracha con Zeke
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>9:00 pm:</b> Huapango con Jorge y Friné
                          </p>
                        </li>
                      </ul>
                      <p>
                        Ya sea que estés perfeccionando tu Quebradita o
                        aprendiendo nuevos pasos de Cumbia, ¡hay algo para
                        todos! Esta es la mejor manera de celebrar la temporada
                        con tus compañeros bailarines.
                      </p>
                      <h4>Precios de entrada:</h4>
                      <ul>
                        <li>
                          <p>
                            <b>$49</b> – Miembros de RF (no reembolsable)
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>$59</b> – Preventa (no reembolsable)
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>$79</b> – Precio en puerta
                          </p>
                        </li>
                      </ul>
                      <p>¡No te pierdas esta increíble noche de baile!</p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=46&sVT=68&sLevel=4&sView=day&sLoc=1&sTrn=100000158&date=10/25/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=48&sVT=65&sView=day&sLoc=1&sTrn=100000131&date=10/26/24">
                      <img
                        src={RF_Halloween_Social}
                        alt="  RF Halloween Social "
                      />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">RF Halloween Social</h3>
                    <div className="news_content_area">
                      <p>
                        Prepárate para una noche espeluznante en nuestro RF
                        Halloween Social! Únete a nosotros en RF Dance Center el
                        sábado 26 de octubre, de 9:00 p.m. a 2:00 a.m. La noche
                        comenzará con una clase especial de Banda impartida por
                        Jorge y Friné, quienes vienen desde México, a las 9:00
                        p.m., mientras que Zeke, desde Chicago, enseñará una
                        clase de Cumbia Guaracha.
                      </p>
                      <p>
                        Después, baila toda la noche con DJ Real Deal y DJ Noe
                        hasta las 2:00 a.m. ¡No olvides venir con tu mejor
                        disfraz de Halloween para tener la oportunidad de ganar
                        $100 en nuestro concurso de disfraces! Disfruta de las
                        aterradoras decoraciones mientras transformamos el lugar
                        en una casa encantada. Las entradas cuestan $20 para
                        miembros de RF (no reembolsable), $30 en preventa para
                        no miembros (no reembolsable) y $50 en la puerta.
                        ¡Asegura tu lugar ahora y no te pierdas el evento de
                        Halloween del año!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=48&sVT=65&sView=day&sLoc=1&sTrn=100000131&date=10/26/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default News;
